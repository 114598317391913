import Auth from '@/models/Auth';

const getLocalStorageViews = () => {
    try {
        const views = JSON.parse(localStorage.getItem('features/views'));
        if (typeof views === 'object' && views !== null) {
            return views;
        }
        return {};
    } catch (error) {
        return {};
    }
};

const setLocalStorageViews = (id, views) => {
    const storedViews = getLocalStorageViews();
    storedViews[`${id}`] = views;
    localStorage.setItem('features/views', JSON.stringify(storedViews));
};

export default {
    features: {
        namespaced: true,
        state: {
            views: {
                ReportsInvestmentsSingleCompany: {
                    disabled: false,
                    active: false
                }
            }
        },
        getters: {
            id: () => () => {
                const auth = Auth();

                if (auth.organisation_id && auth.user_id) {
                    return `${auth.organisation_id}.${auth.user_id}`;
                }

                return 'anonymous';
            },
            views: (state, getters) => {
                let views = state.views;

                const storedViews = getLocalStorageViews();

                if (getters.id() in storedViews) {
                    views = { ...views, ...storedViews[`${getters.id()}`] };
                }

                return views;
            },
            view: (state, getters) => name => getters.views[`${name}`]
        },
        actions: {
            activateView({ commit, getters }, name) {
                commit('ACTIVATE_VIEW', { name, id: getters.id(), views: getters.views });
            },
            deactivateView({ commit, getters }, name) {
                commit('DEACTIVATE_VIEW', { name, id: getters.id(), views: getters.views });
            },
            toggleView({ commit, getters }, name) {
                if (getters.view(name).active) {
                    commit('DEACTIVATE_VIEW', { name, id: getters.id(), views: getters.views });
                } else {
                    commit('ACTIVATE_VIEW', { name, id: getters.id(), views: getters.views });
                }
            }
        },
        mutations: {
            ACTIVATE_VIEW(state, { name, id, views }) {
                views = { ...views, [`${name}`]: { ...views[`${name}`], active: true } };
                setLocalStorageViews(id, views);
            },
            DEACTIVATE_VIEW(state, { name, id, views }) {
                views = { ...views, [`${name}`]: { ...views[`${name}`], active: false } };
                setLocalStorageViews(id, views);
            }
        }
    }
};
