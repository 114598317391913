import { BaseModel } from '@/models';
import i18n from '@/lib/i18n';
import { getExample, findNumbers, parsePhoneNumber, getCountryCodeForRegionCode } from 'awesome-phonenumber';

export class PhoneNumber extends BaseModel {
    static entity = 'phoneNumbers';
    static runSetup = true;

    static fields() {
        return {
            ...super.fields(),
            error: this.string(null).nullable(),
            input: this.string(null).nullable(),
            country: this.string(null).nullable(), // Alpha2Code
            calling_code: this.number(null).nullable(),
            number: this.string(null).nullable(),
            e164: this.string(null).nullable(),
            type_id: this.string(null).nullable(),
            number_type: this.attr(null).nullable()
        };
    }

    async setupLogic() {
        if (!this.$setupLoaded && this.number && !this.input) {
            this.value = this.number;
        }
    }

    get example() {
        try {
            return getExample(this.country || 'GB').number.international;
        } catch (error) {
            return null;
        }
    }

    get value() {
        return this.input || this.number;
    }
    set value(input) {
        this.input = input || '';

        if (!this.input) {
            this.number = null;
            this.e164 = null;
            return;
        }

        if (this.input.length < 7 || this.input.length > 20) {
            this.error = i18n.t('invalid');
            this.number = null;
            this.e164 = null;
            return;
        }

        const parsed = parsePhoneNumber(this.input, {
            regionCode: this.country
        });

        let potential;

        if (!parsed.possible || !parsed.valid) {
            this.error = parsed.possible ? i18n.t('invalid') : parsed.error?.message;
            this.number = null;
            this.e164 = null;

            potential = findNumbers(this.input)?.[0]?.phoneNumber;

            if (!potential) {
                return;
            }
        }

        let match = parsed.valid ? parsed : potential;

        this.error = null;
        this.country = match.regionCode;
        this.calling_code = match.countryCode;
        this.number = match.number.significant;
        this.e164 = match.number.e164;
        this.input = match.number.international;
    }

    get country_code() {
        return this.country;
    }
    set country_code(country_code) {
        const previousCallingCode = this.calling_code || getCountryCodeForRegionCode(this.country);

        this.country = country_code;
        this.calling_code = getCountryCodeForRegionCode(country_code);

        if (this.value && this.value.startsWith('+') && previousCallingCode) {
            this.input = this.value.replace(`+${previousCallingCode}`, `+${this.calling_code}`);
            this.value = this.input;
        }
    }

    get error_messages() {
        if (this.error) {
            return [this.error];
        }

        return null;
    }

    get is_valid() {
        return [this.number, this.country, this.number_type].every(v => v && v.length > 0);
    }

    get computed_name() {
        let name = '';

        if (this.value) {
            name += this.value;
        }

        if (this.number_type) {
            name += ' - ' + this.number_type;
        }

        if (this.number && name) {
            return name;
        }

        return i18n.t('phone_number');
    }
}

export default PhoneNumber;
