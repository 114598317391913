import { Collection, Proposal } from '@/models';
import i18n from '@/lib/i18n';
// import ProposalSolveAmountForEnum from '@/enums/proposal/solveAmountFor';

export class ShortlistEntryCollection extends Collection {
    static type = 'ShortlistEntryCollection';
    static model = Proposal;

    get default_headers() {
        return [
            'name',
            'amount',
            'adviser_fees_get_tax_relief',
            'adviser_fees',
            'platform_fees',
            'initial_adviser_fee',
            'annual_adviser_fee',
            'annual_platform_fee',
            'cleared_funds_required',
            'total_including_fees',
            'maximum_tax_relief'
        ];
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                path: 'offer.name',
                width: '18rem'
            }),
            this.createHeader('sector', i18n.t('investment_sector'), {
                path: 'offer.fund.iht_sector.name',
                width: '12rem'
            }),
            this.createHeader('close_at', i18n.t('close_at'), {
                path: 'offer.close_at',
                format: 'datetime',
                width: '9rem'
            }),
            this.createHeader('min_investment', i18n.t('min_investment'), {
                path: 'offer.min_investment',
                format: 'money',
                width: '10rem'
            }),
            this.createHeader('amount', i18n.t('investment'), {
                format: 'money',
                width: '9rem',
                sortable: false,
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('adviser_fees_get_tax_relief', i18n.t('tax_relief_for_facilitated_fees'), {
                format: 'boolean',
                width: '9rem',
                sortable: false
            }),
            this.createHeader('adviser_fees', i18n.t('adviser_fees'), {
                active: this.Auth().is_investor && !this.Auth().is_xo_investor,
                format: 'money',
                width: '8rem',
                sortable: false,
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('platform_fees', i18n.t('platform_fees'), {
                active: this.Auth().is_investor,
                format: 'money',
                width: '8rem',
                sortable: false,
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('initial_adviser_fee', i18n.t('initial_adviser_fee'), {
                active: this.Auth().is_adviser,
                format: 'money',
                width: '7.6rem',
                sortable: false,
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('annual_adviser_fee', i18n.t('annual_adviser_fee'), {
                active: this.Auth().is_adviser,
                format: 'money',
                width: '8rem',
                sortable: false,
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('annual_platform_fee', i18n.t('annual_platform_fee'), {
                active: this.Auth().is_adviser,
                format: 'money',
                width: '8rem',
                sortable: false,
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('total_including_fees', i18n.t('total_including_fees'), {
                format: 'money',
                width: '8rem',
                sortable: false,
                transform: data => ({
                    amount: data.item.$calc ? data.item.$calc.total_amount_including_fees : null,
                    currency: data.item.currency
                }),
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('cleared_funds_required', i18n.t('required_cleared_funds'), {
                format: 'money',
                width: '9rem',
                sortable: false,
                transform: data => ({
                    amount: data.item.$calc ? data.item.$calc.cleared_funds : null,
                    currency: data.item.currency
                }),
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('maximum_tax_relief', i18n.t('maximum_income_tax_relief'), {
                format: 'money',
                width: '8rem',
                sortable: false,
                transform: data => ({
                    amount: data.item.$calc ? data.item.$calc.maximum_tax_relief : null,
                    currency: data.item.currency
                }),
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default ShortlistEntryCollection;
