import { BaseModel } from '@/models';
import FeeTaxTypeEnum from '@/enums/fee/taxType';
import FeeGroupEnum from '@/enums/fee/group';
import FeeTypeEnum from '@/enums/fee/type';
import FeeSelectorEnum from '@/enums/fee/selector';
import FeeFrequencyEnum from '@/enums/fee/frequency';
import uuid from '@/lib/helpers/uuid';

export class FeeElement extends BaseModel {
    static entity = 'feeElements';
    static primaryKey = 'name';
    static cacheOriginal = true;
    static runSetup = true;

    static fields() {
        return {
            id: this.uid(() => uuid()),
            tax_type: this.enum(FeeTaxTypeEnum).nullable(),
            group: this.enum(FeeGroupEnum).nullable(),
            label: this.string(null).nullable(),
            name: this.string(null).nullable(),
            type: this.enum(FeeTypeEnum).nullable(),
            selector: this.enum(FeeSelectorEnum).nullable(),
            amount: this.attr(null).nullable(),
            percentage: this.number(null).nullable(),
            calculation_percentage: this.number(null).nullable(),
            yesno: this.boolean(null).nullable(),
            description: this.attr(null).nullable(),
            vat: this.boolean(null).nullable(),
            frequency: this.enum(FeeFrequencyEnum).nullable(),
            hurdle_selector: this.enum(FeeSelectorEnum).nullable(),
            hurdle_percentage: this.number(null).nullable(),
            hurdle_description: this.attr(null).nullable(),
            years_reserved: this.number(null).nullable(),
            metadata: this.attr({}).nullable(),
            locked: this.boolean(false)
        };
    }

    async setupLogic() {
        if (this.selectors.length === 1) {
            this.selector = this.selectors[0];
        }

        if (this.frequencies.length === 1) {
            this.frequency = this.frequencies[0];
        }
    }

    get metadata_keys() {
        if ([FeeTaxTypeEnum.EIS, FeeTaxTypeEnum.SEIS].includes(this.tax_type)) {
            switch (this.type) {
                case FeeTypeEnum.ANNUAL_MANAGEMENT:
                    return ['max_years_reserved'];
            }
        }

        return [];
    }

    // Helpers

    is(element) {
        if (element.id && element.id === this.id) {
            return true;
        }
        return (
            this.type === element.type &&
            this.name === element.name &&
            this.label === element.label &&
            this.group === element.group
        );
    }

    like(element) {
        return this.type === element.type && this.group === element.group;
    }

    get type_index() {
        if (!this.name) {
            return -1;
        }
        const parts = this.name.split('_');
        const number = parseInt(parts[parts.length - 1], 10);
        return isNaN(number) ? 1 : number;
    }

    get is_first_of_type() {
        return this.type_index === 1;
    }

    get is_percentage() {
        return this.selector === FeeSelectorEnum.PERCENTAGE;
    }

    get is_money() {
        return this.selector === FeeSelectorEnum.MONEY;
    }

    get is_multiplier() {
        return this.selector === FeeSelectorEnum.MULTIPLIER;
    }

    get is_numeric() {
        return this.selector === FeeSelectorEnum.NUMERIC;
    }

    get is_boolean() {
        return this.selector === FeeSelectorEnum.BOOLEAN;
    }

    get is_hurdle_percentage() {
        return this.hurdle_selector === FeeSelectorEnum.PERCENTAGE;
    }

    get is_hurdle_multiplier() {
        return this.hurdle_selector === FeeSelectorEnum.MULTIPLIER;
    }

    get is_public() {
        switch (this.type) {
            case FeeTypeEnum.AIC_ONGOING:
                return false;
            default:
                return true;
        }
    }

    get is_removable() {
        return !this.locked;
    }

    get is_draggable() {
        return false;
    }

    get group_from_type() {
        switch (this.type) {
            case FeeTypeEnum.INITIAL:
            case FeeTypeEnum.ANNUAL_MANAGEMENT:
            case FeeTypeEnum.CUSTODY:
            case FeeTypeEnum.DEALING:
            case FeeTypeEnum.PERFORMANCE:
            case FeeTypeEnum.EXIT:
            case FeeTypeEnum.DEPLOYMENT_FACTOR:
            case FeeTypeEnum.AIC_ONGOING:
            case FeeTypeEnum.EXPENSE_CAP:
            case FeeTypeEnum.OTHER_INITIAL:
            case FeeTypeEnum.OTHER_ONGOING:
                return FeeGroupEnum.INVESTOR;
            case FeeTypeEnum.INVESTEE_INITIAL:
            case FeeTypeEnum.INVESTEE_ANNUAL_MANAGEMENT:
            case FeeTypeEnum.INVESTEE_ADMINISTRATION:
            case FeeTypeEnum.INVESTEE_DEALING:
            case FeeTypeEnum.INVESTEE_MENTORING:
            case FeeTypeEnum.INVESTEE_OTHER:
            case FeeTypeEnum.INVESTEE_BOARD:
                return FeeGroupEnum.INVESTEE_COMPANY;
            default:
                return FeeGroupEnum.OTHER;
        }
    }

    get name_from_type() {
        if (!this.type) {
            return '';
        }
        return this.type.toLowerCase();
    }

    // Computed fields

    get value() {
        switch (this.selector) {
            case FeeSelectorEnum.PERCENTAGE:
            case FeeSelectorEnum.MULTIPLIER:
                return this.percentage;
            case FeeSelectorEnum.MONEY:
            case FeeSelectorEnum.NUMERIC:
                return this.amount;
            case FeeSelectorEnum.BOOLEAN:
                return this.yesno;
            default:
                return null;
        }
    }

    set value(value) {
        this.percentage = null;
        this.amount = null;
        this.yesno = null;

        if (value === null) {
            return;
        }

        if (this.is_percentage && typeof value !== 'number') {
            throw new Error('Value must be a number for PERCENTAGE selectors');
        }

        if (this.is_multiplier && typeof value !== 'number') {
            throw new Error('Value must be a number for MULTIPLIER selectors');
        }

        if (this.is_money && (typeof value !== 'object' || !('amount' in value) || !('currency' in value))) {
            throw new Error('Value must be a money object for MONEY selectors');
        }

        if (this.is_numeric && typeof value !== 'number') {
            throw new Error('Value must be a number for NUMERIC selectors');
        }

        if (this.is_boolean && typeof value !== 'boolean') {
            throw new Error('Value must be a boolean for BOOLEAN selectors');
        }

        switch (this.selector) {
            case FeeSelectorEnum.PERCENTAGE:
            case FeeSelectorEnum.MULTIPLIER:
                this.percentage = value;
                break;
            case FeeSelectorEnum.MONEY:
            case FeeSelectorEnum.NUMERIC:
                this.amount = value;
                break;
            case FeeSelectorEnum.BOOLEAN:
                this.yesno = value;
                break;
            default:
                throw new Error(`Unsupported selector: ${this.selector}`);
        }
    }

    get hurdle_value() {
        switch (this.selector) {
            case FeeSelectorEnum.PERCENTAGE:
            case FeeSelectorEnum.MULTIPLIER:
                return this.hurdle_percentage;
            default:
                return null;
        }
    }

    set hurdle_value(value) {
        this.hurdle_percentage = null;

        if (value === null) {
            return;
        }

        if (this.is_hurdle_percentage && typeof value !== 'number') {
            throw new Error('Value must be a number for PERCENTAGE hurdle selectors');
        }

        if (this.is_hurdle_multiplier && typeof value !== 'number') {
            throw new Error('Value must be a number for MULTIPLIER hurdle selectors');
        }

        switch (this.hurdle_selector) {
            case FeeSelectorEnum.PERCENTAGE:
            case FeeSelectorEnum.MULTIPLIER:
                this.hurdle_percentage = value;
                break;
            default:
                throw new Error(`Unsupported hurdle selector: ${this.hurdle_selector}`);
        }
    }

    // Enum values

    get selectors() {
        switch (this.type) {
            case FeeTypeEnum.INITIAL:
            case FeeTypeEnum.ANNUAL_MANAGEMENT:
            case FeeTypeEnum.CUSTODY:
            case FeeTypeEnum.DEALING:
            case FeeTypeEnum.OTHER_INITIAL:
            case FeeTypeEnum.OTHER_ONGOING:
            case FeeTypeEnum.EXIT:
            case FeeTypeEnum.INVESTEE_INITIAL:
            case FeeTypeEnum.INVESTEE_ANNUAL_MANAGEMENT:
            case FeeTypeEnum.INVESTEE_ADMINISTRATION:
            case FeeTypeEnum.INVESTEE_DEALING:
            case FeeTypeEnum.INVESTEE_MENTORING:
            case FeeTypeEnum.INVESTEE_OTHER:
            case FeeTypeEnum.INVESTEE_BOARD:
                return [FeeSelectorEnum.PERCENTAGE, FeeSelectorEnum.MONEY];

            case FeeTypeEnum.PERFORMANCE:
            case FeeTypeEnum.DEPLOYMENT_FACTOR:
            case FeeTypeEnum.AIC_ONGOING:
            case FeeTypeEnum.EXPENSE_CAP:
                return [FeeSelectorEnum.PERCENTAGE];

            default:
                return Object.keys(FeeSelectorEnum);
        }
    }

    get frequencies() {
        switch (this.type) {
            case FeeTypeEnum.ANNUAL_MANAGEMENT:
            case FeeTypeEnum.CUSTODY:
            case FeeTypeEnum.OTHER_ONGOING:
            case FeeTypeEnum.INVESTEE_ANNUAL_MANAGEMENT:
            case FeeTypeEnum.AIC_ONGOING:
            case FeeTypeEnum.EXPENSE_CAP:
                return [FeeFrequencyEnum.ANNUAL];

            case FeeTypeEnum.INVESTEE_ADMINISTRATION:
            case FeeTypeEnum.INVESTEE_MENTORING:
            case FeeTypeEnum.INVESTEE_OTHER:
            case FeeTypeEnum.INVESTEE_BOARD:
                return [FeeFrequencyEnum.ANNUAL, FeeFrequencyEnum.MONTHLY];

            default:
                return [];
        }
    }

    get hurdle_selectors() {
        switch (this.type) {
            case FeeTypeEnum.PERFORMANCE:
                return [FeeSelectorEnum.PERCENTAGE, FeeSelectorEnum.MULTIPLIER];

            default:
                return [];
        }
    }

    // Display conditions

    get show_frequency_field() {
        if (!this.selector) {
            return false;
        }
        return this.frequencies.length > 0;
    }

    get show_vat_field() {
        switch (this.type) {
            case FeeTypeEnum.DEPLOYMENT_FACTOR:
                return false;
        }

        switch (this.selector) {
            case FeeSelectorEnum.PERCENTAGE:
            case FeeSelectorEnum.MONEY:
                return true;
            default:
                return false;
        }
    }

    get show_years_reserved_field() {
        if (!this.selector) {
            return false;
        }
        switch (this.type) {
            case FeeTypeEnum.ANNUAL_MANAGEMENT:
            case FeeTypeEnum.OTHER_ONGOING:
                return this.tax_type !== FeeTaxTypeEnum.VCT;
            case FeeTypeEnum.CUSTODY:
            case FeeTypeEnum.INVESTEE_ANNUAL_MANAGEMENT:
            case FeeTypeEnum.INVESTEE_ADMINISTRATION:
            case FeeTypeEnum.INVESTEE_MENTORING:
            case FeeTypeEnum.INVESTEE_OTHER:
            case FeeTypeEnum.INVESTEE_BOARD:
                return true;
            default:
                return false;
        }
    }

    get show_hurdle_fields() {
        if (!this.selector) {
            return false;
        }
        return this.hurdle_selectors.length > 0;
    }
}

export default FeeElement;
