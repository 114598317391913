import {
    BaseModel,
    Product,
    Company,
    ProductOffer,
    Fund,
    FundManager,
    Provider,
    Nominee,
    Custodian,
    Registrar,
    Introducer,
    ReceivingAgent,
    BusinessStage,
    Integration,
    FeePlan,
    FeeElement
} from '@/models';

import OfferStatusEnum from '@/enums/offer/status';
import OfferWorkflowStatusEnum from '@/enums/offer/workflowStatus';
import TargetDividendTypeEnum from '@/enums/targetDividendType';
import clock from '@/lib/clock';
import findNextOfferEvent from '@/lib/helpers/findNextOfferEvent';
import getTargetDividend from '@/lib/helpers/getTargetDividend';
import get from 'lodash/get';
import head from 'lodash/head';
import filter from 'lodash/filter';
import has from 'lodash/has';
import OfferApi from '@/api/OfferApi';
import SDRLabelEnum from '@/enums/SDRLabel';
import ProposalRedistributionEnum from '@/enums/proposal/redistribution';
import InvestorCategoryEnum from '@/enums/investor/category';
import toDate from '@/lib/helpers/toDate';
import FeeTypeEnum from '@/enums/fee/type';
import FeeSelectorEnum from '@/enums/fee/selector';
import FeeGroupEnum from '@/enums/fee/group';
import i18n from '@/lib/i18n';

export class Offer extends BaseModel {
    static name = 'Offer';
    static entity = 'offers';
    static Api = OfferApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.attr(null),
            logo_id: this.attr(null),
            logo_url: this.string(null).nullable(),
            hero_image_id: this.attr(null),
            hero_image_url: this.string(null).nullable(),
            name: this.string(null).nullable(),
            gi_ref: this.string(null).nullable(),
            is_fund_offer: this.boolean(null).nullable(),
            status: this.enum(OfferStatusEnum).nullable(),
            workflow_status: this.enum(OfferWorkflowStatusEnum, OfferWorkflowStatusEnum.DRAFT),
            type: this.string(null).nullable(),
            tax_status: this.string(null).nullable(),
            display_section: this.string(null).nullable(),
            description: this.attr(null).nullable(),
            created_at: this.string(clock().format('YYYY-MM-DDTHH:mm:ss')),
            open_at: this.string(null).nullable(),
            launch_at: this.string(null).nullable(),
            close_at: this.string(null).nullable(),
            existing_investor_close_at: this.string(null).nullable(),
            existing_investor_close_description: this.attr(null).nullable(),
            deadline_at: this.string(null).nullable(),
            deadline_details: this.attr(null).nullable(),
            early_bird_offer: this.string(null).nullable(),
            early_bird_open_at: this.string(null).nullable(),
            early_bird_close_at: this.string(null).nullable(),
            early_bird_discount: this.number(null).nullable(),
            early_bird_nominal: this.attr(null).nullable(),
            min_size: this.attr(null).nullable(),
            max_size: this.attr(this.defaultMoney),
            min_investment: this.attr(this.defaultMoney),
            max_investment: this.attr(null).nullable(),
            raised: this.attr(null).nullable(),
            initial_charge: this.attr(null).nullable(),
            over_allotment_available: this.boolean(null).nullable(),
            over_allotment_proposed: this.attr(null).nullable(),
            over_allotment_actual: this.attr(null).nullable(),
            next_allotment_at: this.string(null).nullable(),
            gi_discount: this.number(null).nullable(),
            gi_existing_investor_discount: this.number(null).nullable(),
            loyalty_discount: this.number(null).nullable(),
            equity_issued: this.number(null).nullable(),
            shares_issued: this.number(null).nullable(),
            shares_offered: this.number(null).nullable(),
            share_nominal_value: this.attr(null).nullable(),
            target: this.attr(null).nullable(),
            target_irr: this.number(null).nullable(),
            target_dividend_type: this.enum(TargetDividendTypeEnum).nullable(),
            target_dividend: this.number(null).nullable(),
            target_return: this.number(null).nullable(),
            target_return_details: this.attr(null).nullable(),
            pre_money_valuation: this.attr(null).nullable(),
            post_money_valuation: this.attr(null).nullable(),
            max_adviser_facilitated: this.number(null).nullable(),
            max_commission: this.number(null).nullable(),
            revenue_forecast: this.attr(null).nullable(),
            ebitda_forecast: this.attr(null).nullable(),
            gi_app_form: this.boolean(null).nullable(),
            prefilled_app_form: this.boolean(null).nullable(),
            offline_app_form: this.boolean(null).nullable(),
            overview: this.attr(null).nullable(),
            dividend_policy: this.attr(null).nullable(),
            management_overview: this.attr(null).nullable(),
            business_description: this.attr(null).nullable(),
            elevator_pitch: this.attr(null).nullable(),
            funding_to_date: this.attr(null).nullable(),
            income_details: this.attr(null).nullable(),
            industry_details: this.attr(null).nullable(),
            team_details: this.attr(null).nullable(),
            exit_strategy: this.attr(null).nullable(),
            profitability_forecast: this.attr(null).nullable(),
            max_fund_size_details: this.attr(null).nullable(),
            single_company: this.boolean(null).nullable(),
            downloads: this.attr([]).nullable(),
            events: this.attr([]).nullable(),
            is_multi_product: this.boolean(false).nullable(),
            base_url_path: this.string(null).nullable(),
            template: this.attr(null).nullable(),
            integration_path: this.string(null).nullable(),
            integration_id: this.string(null).nullable(),
            integration: this.belongsTo(Integration, 'integration_id'),
            data_template: this.attr(null).nullable(),
            in_custody: this.boolean(null).nullable(),
            provides_tax_relief_on_adviser_fees: this.boolean(false).nullable(),
            introduction_letter: this.attr(null).nullable(),
            terms_and_conditions: this.attr(null).nullable(),
            risk_warning: this.attr(null).nullable(),
            available_redistribution: this.enumList(ProposalRedistributionEnum).nullable(),
            restrict_to_investor_categories: this.enumList(InvestorCategoryEnum).nullable(),

            offer_cost_amount: this.attr(null).nullable(),
            offer_cost_percentage: this.number(null).nullable(),
            offer_cost_type: this.enum(FeeSelectorEnum).nullable(),
            offer_cost_vat: this.boolean(null).nullable(),
            offer_cost_description: this.attr(null).nullable(),
            offer_cost_fee_element: this.model(FeeElement, null).nullable(),
            investee_fee_description: this.attr(null).nullable(),

            key_media_application: this.attr(null).nullable(),
            key_media_application_id: this.string(null).nullable(),

            key_media_certification: this.attr(null).nullable(),
            key_media_certification_id: this.string(null).nullable(),

            key_media_due_diligence: this.attr(null).nullable(),
            key_media_due_diligence_id: this.string(null).nullable(),

            key_media_investment_deck: this.attr(null).nullable(),
            key_media_investment_deck_id: this.string(null).nullable(),

            key_media_key_information_document: this.attr(null).nullable(),
            key_media_key_information_document_id: this.string(null).nullable(),

            key_media_prospectus: this.attr(null).nullable(),
            key_media_prospectus_id: this.string(null).nullable(),

            key_media_summary: this.attr(null).nullable(),
            key_media_summary_id: this.string(null).nullable(),

            product_offers: this.hasMany(ProductOffer, 'offer_id'),
            products: this.belongsToMany(Product, ProductOffer, 'offer_id', 'product_id'),
            product_overview: this.attr(null).nullable(),

            fund_id: this.string(null).nullable(),
            fund: this.belongsTo(Fund, 'fund_id'),
            fund_overview: this.attr(null).nullable(),

            fund_manager_details: this.attr(null).nullable(),
            provider_overview: this.attr(null).nullable(),

            registrar_id: this.string(null).nullable(),
            registrar: this.belongsTo(Registrar, 'registrar_id'),

            introducer_id: this.string(null).nullable(),
            introducer: this.belongsTo(Introducer, 'introducer_id'),

            receiving_agent_id: this.string(null).nullable(),
            receiving_agent: this.belongsTo(ReceivingAgent, 'receiving_agent_id'),

            business_stage_id: this.string(null).nullable(),
            business_stage: this.belongsTo(BusinessStage, 'business_stage_id'),

            is_via_fund: this.boolean(null).nullable(),
            via_fund_id: this.string(null).nullable(),
            via_fund: this.belongsTo(Fund, 'via_fund_id'),

            panels: this.attr([]).nullable(),

            primary_exchange: this.string(null).nullable(),
            existing_shareholder_discount: this.number(null).nullable(),
            growthinvest_discount: this.string(null).nullable(),
            required_signatories: this.attr([]).nullable(),
            application_form_required_signatures: this.attr([]).nullable()
        };
    }

    get can_be_viewed() {
        return true;
    }

    get can_be_edited() {
        return true;
    }

    get can_be_copied() {
        return true;
    }

    get can_be_deleted() {
        return !this.is_live;
    }

    getOfferCostFeeElement() {
        return new FeeElement({
            name: 'offer_cost',
            label: i18n.t('offer_cost'),
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.INITIAL,
            amount: this.offer_cost_amount,
            percentage: this.offer_cost_percentage,
            selector: this.offer_cost_type,
            vat: this.offer_cost_vat,
            description: this.offer_cost_description
        });
    }

    get offer_cost() {
        if (!this.offer_cost_fee_element) {
            this.offer_cost_fee_element = this.getOfferCostFeeElement();
        }

        return this.offer_cost_fee_element;
    }

    set offer_cost(value) {
        if (!value) {
            this.offer_cost_amount = null;
            this.offer_cost_percentage = null;
            this.offer_cost_type = null;
            this.offer_cost_vat = null;
            this.offer_cost_description = null;
        } else {
            this.offer_cost_amount = value.amount;
            this.offer_cost_percentage = value.percentage;
            this.offer_cost_type = value.selector;
            this.offer_cost_vat = value.vat;
            this.offer_cost_description = value.description;
        }

        if (!this.offer_cost_fee_element) {
            this.offer_cost_fee_element = this.getOfferCostFeeElement();
        } else {
            this.offer_cost_fee_element.amount = this.offer_cost_amount;
            this.offer_cost_fee_element.percentage = this.offer_cost_percentage;
            this.offer_cost_fee_element.selector = this.offer_cost_type;
            this.offer_cost_fee_element.vat = this.offer_cost_vat;
            this.offer_cost_fee_element.description = this.offer_cost_description;
        }
    }

    get over_allotment() {
        if (this.over_allotment_actual) {
            return this.over_allotment_actual;
        } else if (this.over_allotment_proposed) {
            return this.over_allotment_proposed;
        }

        return null;
    }

    get fund_managers() {
        if (this.fund && this.fund.fund_manager) {
            return [this.fund.fund_manager];
        }

        if (this.products && this.products.length) {
            return this.products.filter(product => !!product.fund_manager).map(product => product.fund_manager);
        }

        return [];
    }

    get fund_manager() {
        if (this.fund_managers.length) {
            return this.fund_managers[0];
        }
        return new FundManager();
    }

    get providers() {
        if (this.fund && this.fund.provider) {
            return [this.fund.provider];
        }

        if (this.products && this.products.length) {
            return this.products.filter(product => !!product.provider).map(product => product.provider);
        }

        return [];
    }

    get provider() {
        if (this.providers.length) {
            return this.providers[0];
        }
        return new Provider();
    }

    get nominee() {
        if (!this.is_fund) {
            return null;
        }

        if (!this.fund.nominee) {
            return new Nominee();
        }

        return this.fund.nominee;
    }

    get custodian() {
        if (!this.is_fund) {
            return null;
        }

        if (!this.fund.custodian) {
            return new Custodian();
        }

        return this.fund.custodian;
    }

    get social_links() {
        return {
            // todo: add social links
        };
    }

    get news_items() {
        if (this.is_fund) {
            return this.fund.news_items || [];
        }

        let news = [];

        for (let company of this.companies) {
            news = news.concat(company.news_items || []);
        }

        return news;
    }

    get twitter_user() {
        if (this.is_fund) {
            return this.provider.twitter_user;
        }

        return this.company.twitter_user;
    }

    //

    get has_fund() {
        return !!this.fund_id;
    }

    get is_fund() {
        return this.is_fund_offer || this.has_fund || false;
    }

    get is_single_company() {
        return this.single_company || false;
    }

    // PMI

    get is_pmi() {
        return this.tax_status === 'PMI';
    }

    get is_pmi_fund() {
        return this.is_pmi && this.is_fund;
    }

    get is_pmi_single_company() {
        return this.is_pmi && this.is_single_company;
    }

    // ALTS

    get is_alt() {
        return this.tax_status === 'ALTS';
    }

    get is_alt_fund() {
        return this.is_alt && this.is_fund;
    }

    get is_alt_single_company() {
        return this.is_alt && this.is_single_company;
    }

    // EIS

    get is_eis() {
        return this.tax_status === 'EIS';
    }

    get is_eis_fund() {
        return this.is_eis && this.is_fund;
    }

    get is_eis_single_company() {
        return this.is_eis && this.is_single_company;
    }

    // HYBRID

    get is_hybrid() {
        return this.tax_status === 'HYBRID';
    }

    get is_hybrid_fund() {
        return this.is_hybrid && this.is_fund;
    }

    get is_hybrid_single_company() {
        return this.is_hybrid && this.is_single_company;
    }

    // IHT

    get is_iht() {
        return this.tax_status === 'IHT';
    }

    get is_iht_fund() {
        return this.is_iht && this.is_fund;
    }

    get is_iht_single_company() {
        // Note: Should always return false for VCT
        return this.is_iht && this.is_single_company;
    }

    // NQ

    get is_non_qualifying() {
        return this.tax_status === 'NQ';
    }

    get is_non_qualifying_fund() {
        return this.is_non_qualifying && this.is_fund;
    }

    get is_non_qualifying_single_company() {
        return this.is_non_qualifying && this.is_single_company;
    }

    // SEIS

    get is_seis() {
        return this.tax_status === 'SEIS';
    }

    get is_seis_fund() {
        return this.is_seis && this.is_fund;
    }

    get is_seis_single_company() {
        return this.is_seis && this.is_single_company;
    }

    // SITR

    get is_social() {
        return this.tax_status === 'SITR';
    }

    get is_social_fund() {
        return this.is_social && this.is_fund;
    }

    get is_social_single_company() {
        return this.is_social && this.is_single_company;
    }

    // VCT

    get is_vct() {
        return this.tax_status === 'VCT';
    }

    get is_vct_fund() {
        return this.is_vct && this.is_fund;
    }

    get is_vct_single_company() {
        // Note: Should always return false for VCT
        return this.is_vct && this.is_single_company;
    }

    // SEIS/EIS

    get is_eis_or_seis() {
        return this.is_eis || this.is_seis || this.tax_status === 'SEIS/EIS';
    }

    get is_eis_or_seis_fund() {
        return this.is_eis_or_seis && this.is_fund;
    }

    get is_eis_or_seis_single_company() {
        return this.is_eis_or_seis && this.is_single_company;
    }

    //

    get tax_relief_on_adviser_fee() {
        if (!this.product_offers) {
            return false;
        }

        return this.product_offers.some(po => {
            return po.product?.company?.tax_relief_on_adviser_fee;
        });
    }

    get companies() {
        return this.products.map(product => product.company) || [];
    }

    get company() {
        return this.companies[0] || new Company();
    }

    get product() {
        return this.products[0] || new Product();
    }

    get product_offer() {
        return this.product_offers[0];
    }

    get closed_products() {
        if (!this.has_multiple_products) {
            return [];
        }

        return this.product_offers
            .filter(po => !po.$deleted)
            .filter(po => po.closed_at && toDate(po.closed_at, false).isBefore(clock()))
            .map(po => po.product);
    }

    get open_products() {
        if (!this.has_multiple_products) {
            return [];
        }

        return this.product_offers
            .filter(po => !po.$deleted)
            .filter(po => !po.closed_at || toDate(po.closed_at, false).isAfter(clock()))
            .map(po => po.product);
    }

    get has_no_open_products() {
        if (!this.has_multiple_products) {
            return false;
        }

        if (!this.closed_products || !this.closed_products.length) {
            return false;
        }

        return this.products.length === this.closed_products.length;
    }

    getOfferProduct(productId) {
        return this.product_offers.find(po => po.product.id === productId && !po.$deleted) || null;
    }

    get closed_product_ids() {
        return this.closed_products.map(product => product.id);
    }

    get has_multiple_products() {
        if (!this.products) {
            return false;
        }

        return this.product_offers.filter(po => !po.$deleted).map(po => po.product).length > 1;
    }

    get sector() {
        if (this.is_single_company) {
            return this.products
                .map(product => {
                    return product.sector.name;
                })
                .join(', ');
        }

        return this.fund?.iht_sector?.name || null;
    }

    set sector(value) {
        // todo
    }

    get listing() {
        return this.product.listing;
    }

    get next_offer_event() {
        return findNextOfferEvent(this);
    }

    get target_dividend_display() {
        return getTargetDividend(this.target_dividend_type, this.target_dividend);
    }

    get has_events() {
        return this.events.length > 0;
    }

    get currency() {
        return get(head(filter(this, k => has(k, 'currency'))), 'currency');
    }

    get team() {
        // todo;
        return [];
    }

    get share_class() {
        return this.product.share_class;
    }

    get is_live() {
        return this.workflow_status === OfferWorkflowStatusEnum.LIVE;
    }

    get is_draft() {
        return this.workflow_status === OfferWorkflowStatusEnum.DRAFT;
    }

    get workflow_status_colour() {
        if (this.is_live) {
            return 'success';
        } else if (this.is_draft) {
            return null;
        }

        return null;
    }

    get is_evergreen() {
        return this.status === OfferStatusEnum.EVERGREEN;
    }

    get is_open() {
        return this.status === OfferStatusEnum.OPEN;
    }

    get is_closed() {
        if (this.is_evergreen) {
            return false;
        }
        return this.status === OfferStatusEnum.CLOSED || this.getDaysUntilClosing() <= 0;
    }

    get is_coming_soon() {
        return this.status === OfferStatusEnum.COMING_SOON;
    }

    get status_colour() {
        if (this.is_evergreen) {
            return 'success';
        } else if (this.is_open) {
            return 'info';
        } else if (this.is_closed) {
            return 'error';
        } else if (this.is_coming_soon) {
            return 'warning';
        }

        return null;
    }

    isClosingIn(value, unit = 'days') {
        if (!this.close_at) {
            return false;
        }

        const closingAt = clock(this.close_at);
        const now = clock();

        return closingAt.diff(now, unit) <= value;
    }

    getDaysUntilClosing() {
        if (!this.close_at) {
            return null;
        }

        const closingAt = clock(this.close_at);
        const now = clock();

        return closingAt.diff(now, 'days');
    }

    get sdr_labels() {
        const labels = new Set();

        if (this.is_fund && this.fund.sdr_label && this.fund.sdr_label !== SDRLabelEnum.NO_LABEL) {
            labels.add(this.fund.sdr_label);
        }

        if (this.products && this.products.length) {
            this.products.forEach(product => {
                if (product.sdr_label && product.sdr_label !== SDRLabelEnum.NO_LABEL) {
                    labels.add(product.sdr_label);
                }
            });
        }

        return Array.from(labels);
    }

    isValidInvestmentAmount(amount) {
        if (!this.min_investment || !this.min_investment.amount) {
            return true;
        }

        if (typeof amount === 'object' && amount.amount) {
            amount = amount.amount;
        }

        if (isNaN(amount) || amount <= 0) {
            return false;
        }

        return amount >= this.min_investment.amount;
    }

    isAmountMinimumValid(amount) {
        if (!this.min_investment || !this.min_investment.amount) {
            return true;
        }

        if (typeof amount === 'object' && amount.amount) {
            amount = amount.amount;
        }

        if (isNaN(amount) || amount <= 0) {
            return false;
        }

        return amount >= this.min_investment.amount;
    }

    isAmountMaximumValid(amount) {
        if (!this.max_investment || !this.max_investment.amount) {
            return true;
        }

        if (typeof amount === 'object' && amount.amount) {
            amount = amount.amount;
        }

        if (isNaN(amount) || amount <= 0) {
            return false;
        }

        return amount <= this.max_investment.amount;
    }

    getFee(attrs, parent_id = null) {
        const plans = this.fee_plans.filter(plan => !parent_id || plan.parent?.id === parent_id);

        if (!plans.length) {
            return null;
        }

        let fee = null;

        for (const plan of plans) {
            fee = plan.entries.find(fee =>
                Object.keys(attrs).every(attrKey => attrs[`${attrKey}`] === fee[`${attrKey}`])
            );

            if (fee) {
                break;
            }
        }

        if (!fee) {
            return null;
        }

        if (!(fee instanceof FeeElement)) {
            fee = new FeeElement(fee);
        }

        return fee;
    }

    get fee_plans() {
        let plans = [];

        if (this.is_fund) {
            if (this.fund && this.fund.fee_plan) {
                this.fund.fee_plan.parent = {
                    id: this.fund.id,
                    name: this.fund.name
                };

                plans.push({ ...this.fund.fee_plan });
            }
        } else if (this.products && this.products.length) {
            this.products.forEach(product => {
                if (product.fee_plan) {
                    product.fee_plan.parent = {
                        id: product.id,
                        name: product.name
                    };
                    plans.push({ ...product.fee_plan });
                }
            });
        }

        plans = plans.map(plan => {
            if (!(plan instanceof FeePlan)) {
                plan = new FeePlan(plan);
            }
            return plan;
        });

        if (this.is_vct && this.offer_cost.value !== null) {
            plans.unshift(
                new FeePlan({
                    entries: [this.offer_cost],
                    parent: { id: this.id, name: i18n.t('offer') }
                })
            );
        }

        if (this.investee_fee_description) {
            const offerPlan = plans.find(plan => plan.parent.id === this.id);
            if (offerPlan) {
                offerPlan.group_descriptions = { [FeeGroupEnum.INVESTEE_COMPANY]: this.investee_fee_description };
            } else {
                plans.unshift(
                    new FeePlan({
                        parent: { id: this.id, name: i18n.t('offer') },
                        group_descriptions: { [FeeGroupEnum.INVESTEE_COMPANY]: this.investee_fee_description }
                    })
                );
            }
        }

        return plans;
    }
}

export default Offer;
