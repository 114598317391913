import { Editor } from '@tiptap/vue-2';
import extensions from '@/lib/tiptap/extensions';

const createEditor = (options = {}) => {
    let exts = extensions;

    if (options.exclude && Array.isArray(options.exclude)) {
        exts = extensions.filter(ex => !options.exclude.includes(ex.name));
    }

    options = {
        extensions: exts,
        content: null,
        autofocus: false,
        editable: true,
        injectCSS: true,
        onBeforeCreate: () => {}, // Hook for before view creation
        onCreate: () => {}, // Hook for when the editor is ready
        onUpdate: () => {}, // Hook for when the editor updates
        onSelectionUpdate: () => {}, // Hook for selection changes
        onTransaction: () => {}, // Hook for state changes
        onFocus: () => {}, // Hook for focus events
        onBlur: () => {}, // Hook for blur events
        onDestroy: () => {}, // Hook for when editor is destroyed
        editorProps: {
            transformPastedText: text => text, // Transform pasted text
            transformPastedHTML: html => html // Transform pasted HTML
        },
        onUpdateOutline: null,
        ...options
    };

    // Disable 'openOnClick' for the link extension when editable
    if (options.editable) {
        options.extensions = options.extensions.map(extension =>
            extension.name === 'link'
                ? { ...extension, options: { ...extension.options, openOnClick: false } }
                : extension
        );
    }

    // Configure outline extension if 'onUpdateOutline' is provided
    if (options.onUpdateOutline) {
        options.extensions = options.extensions.map(extension =>
            extension.name === 'outline' ? extension.configure({ onUpdate: options.onUpdateOutline }) : extension
        );
    }

    try {
        return new Editor(options);
    } catch (error) {
        console.error('Error creating Tiptap editor:', error);
        throw error;
    }
};

export default createEditor;
