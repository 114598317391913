import { OfferCollection } from '@/models';
import i18n from '@/lib/i18n';
import ihtType from '@/enums/ihtType';
import OfferStatusEnum from '@/enums/offer/status';
import OfferWorkflowStatusEnum from '@/enums/offer/workflowStatus';
import SDRLabelEnum from '@/enums/SDRLabel';
import FeeTypeEnum from '@/enums/fee/type';

export class OfferIhtCollection extends OfferCollection {
    static type = 'OfferIhtCollection';

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                sortable: true
            }),
            this.createHeader('workflow_status', i18n.t('workflow_status'), {
                active: false,
                format: 'enum',
                width: '9rem',
                filterable: this.filter_workflow_status
                    ? {
                          operators: this.getOperators(['eq', 'neq', 'in'])
                      }
                    : false,
                enum: OfferWorkflowStatusEnum,
                enumA11yKey: 'enums.offer_workflow_status',
                enumTypes: {
                    [OfferWorkflowStatusEnum.DRAFT]: 'grey',
                    [OfferWorkflowStatusEnum.LIVE]: 'success'
                }
            }),
            this.createHeader('status', i18n.t('status'), {
                format: 'enum',
                width: '7rem',
                filterable: true,
                sortable: true,
                enum: OfferStatusEnum,
                enumA11yKey: 'enums.offer_status',
                enumTypes: {
                    [OfferStatusEnum.OPEN]: 'info',
                    [OfferStatusEnum.CLOSED]: 'error',
                    [OfferStatusEnum.EVERGREEN]: 'success',
                    [OfferStatusEnum.COMING_SOON]: 'warning'
                }
            }),
            this.createHeader('max_fund_size_details', i18n.t('max_fund_size_details'), {
                format: 'wysiwyg',
                width: '25rem'
            }),
            this.createHeader('iht_type', i18n.t('investor_shareholding_type'), {
                format: 'enum',
                filterable: true,
                enum: ihtType,
                enumA11yKey: 'enums.iht_type',
                width: '11rem'
            }),
            this.createHeader('iht_relief', i18n.t('iht_relief'), {
                width: '5rem',
                filterable: true,
                format: 'boolean'
            }),
            this.createHeader('min_investment', i18n.t('min_investment'), {
                format: 'money',
                width: '10rem'
            }),
            this.createHeader('fund_manager', i18n.t('fund_manager'), {
                path: 'fund.fund_manager.name',
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('provider', i18n.t('provider'), {
                path: 'fund.provider.name',
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('nominee', i18n.t('nominee'), {
                path: 'fund.nominee.name',
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('custodian', i18n.t('custodian'), {
                path: 'fund.custodian.name',
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('sector', i18n.t('investment_sector'), {
                path: 'fund.iht_sector.name',
                width: '24rem'
            }),
            this.createHeader('investment_strategy', i18n.t('investment_strategy'), {
                path: 'fund.investment_strategy.name',
                width: '18rem',
                minWidth: '18rem'
            }),
            this.createHeader('esg_qualifying', i18n.t('esg_qualifying'), {
                width: '5rem',
                filterable: true,
                format: 'boolean'
            }),
            this.createHeader('raised', i18n.t('funds_raised'), {
                filterable: true,
                format: 'money',
                width: '9rem'
            }),
            this.createHeader('initial_charge', i18n.t('initial_charge'), {
                filterable: true,
                format: 'percentage',
                width: '9rem',
                transform: data =>
                    data.item.getFee({
                        type: FeeTypeEnum.INITIAL
                    })
            }),

            this.createHeader('sdr_label', i18n.t('sdr_label'), {
                format: 'enum',
                filterable: true,
                enum: SDRLabelEnum,
                enumA11yKey: 'enums.sdr_label',
                width: '8rem',
                active: false
            })
        ];
    }

    get default_headers() {
        return [
            'name',
            'status',
            'max_fund_size_details',
            'min_investment',
            'sector',
            'investment_strategy',
            'initial_charge'
        ];
    }

    get preset_filters() {
        return {
            ...super.preset_filters,
            tax_status: this.filterOperator('is') + ':IHT'
        };
    }
}

export default OfferIhtCollection;
