import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store';

import features from './middleware/features';
import checkBrowserCompatibility from './middleware/checkBrowserCompatibility';
import setRouterHistory from './middleware/setRouterHistory';
import clearQueues from './middleware/clearQueues';
import checkUrlForContext from './middleware/checkUrlForContext';
import closeAfterSuccessfulLogin from './middleware/closeAfterSuccessfulLogin';
import loadUser from './middleware/loadUser';
import loadRegistration from './middleware/loadRegistration';
import loadOrganisation from './middleware/loadOrganisation';
import loadAccount from './middleware/loadAccount';
import urlGuard from './middleware/urlGuard';
import setFreshdeskScope from './middleware/setFreshdeskScope';
import setSentryScope from './middleware/setSentryScope';
import setInvestor from './middleware/setInvestor';
import setAdviser from './middleware/setAdviser';
import redirectSuspendedOrganisation from './middleware/redirectSuspendedOrganisation';
import categorisationExpired from './middleware/categorisationExpired';
import redirectIncompleteInvestor from './middleware/redirectIncompleteInvestor';
import loadBranding from './middleware/loadBranding';
import discoverLocale from './middleware/discoverLocale';
import preload from './middleware/preload';
import setPageTitle from './middleware/setPageTitle';
import resetQuerySelectors from './middleware/resetQuerySelectors';
import checkTermsConditions from './middleware/checkTermsConditions';

const { push } = VueRouter.prototype;
const { isNavigationFailure, NavigationFailureType } = VueRouter;

const onRouterError = error => {
    if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
        // console.warn(error);
    } else if (isNavigationFailure(error, NavigationFailureType.redirected)) {
        // console.warn(error);
    } else if (isNavigationFailure(error, NavigationFailureType.aborted)) {
        // console.warn(error);
    } else if (isNavigationFailure(error, NavigationFailureType.cancelled)) {
        // console.warn(error);
    } else {
        throw error;
    }
};

VueRouter.prototype.push = function (location) {
    return push.call(this, location).catch(onRouterError);
};

VueRouter.prototype.replace = function (location) {
    return push.call(this, location).catch(onRouterError);
};

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkExactActiveClass: 'is-active',
    routes
});

// Check the users browser for compatibility
router.beforeEach(checkBrowserCompatibility);

// Set the router history store state;
router.beforeEach(setRouterHistory);

// Clear all snackbar items;
router.beforeEach(clearQueues);

// Close the current window if close-after-successful-login is true
router.beforeEach(closeAfterSuccessfulLogin);

// Set localStorage item when switching accounts in the same organisation;
router.beforeEach(checkUrlForContext);

// Set the current user in the auth store;
router.beforeEach(loadUser);

// Set the current registration in the auth store;
router.beforeEach(loadRegistration);

// Set the organisation in the auth store;
router.beforeEach(loadOrganisation);

// Set the account in the auth store;
router.beforeEach(loadAccount);

// Change context to the organisation/account for the current URL;
router.beforeEach(urlGuard);

// Replace route component based on feature flags
router.beforeEach(features);

// Set Freshdesk scope
router.beforeEach(setFreshdeskScope);

// Set Sentry scope
router.beforeEach(setSentryScope);

// Set the investor in the auth store;
router.beforeEach(setInvestor);

// Set the adviser in the auth store;
router.beforeEach(setAdviser);

// Check the users' terms and conditions are valid
router.beforeEach(checkTermsConditions);

// Redirect a suspended organisation
router.beforeEach(redirectSuspendedOrganisation);

// Check if the user needs to recategorise
router.beforeEach(categorisationExpired);

// Redirect an incomplete investor to their settings
router.beforeEach(redirectIncompleteInvestor);

// Set the branding in the auth store;
router.beforeEach(loadBranding);

// Set the users current locale;
router.beforeEach(discoverLocale);

// Preload data into vuex;
router.beforeEach(preload);

// Set the browser page title;
router.beforeEach(setPageTitle);

// Reset pinned investor
router.beforeEach(resetQuerySelectors);

router.afterEach(() => {
    store.dispatch('app/setBreadcrumbs', []);
});

export default router;
