import { Collection, Integration, Adviser } from '@/models';
import i18n from '@/lib/i18n';
import IntegrationTypeEnum from '@/enums/integration/type';
import IntegrationStatusEnum from '@/enums/integration/status';

export class IntegrationCollection extends Collection {
    static type = 'IntegrationCollection';
    static endpoint = Integration.api.index;
    static model = Integration;

    static fields() {
        return {
            ...super.fields(),
            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id')
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.adviser_id) {
            filters.adviser_id = this.filterOperator('is') + ':' + this.adviser_id;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('name', i18n.t('name'), {
                width: '14rem',
                minWidth: '14rem',
                filterable: true,
                sortable: true
            }),
            this.createHeader('type', i18n.t('type'), {
                format: 'enum',
                filterable: true,
                enum: IntegrationTypeEnum,
                enumA11yKey: 'enums.integration_type',
                width: '8rem',
                sortable: true
            }),
            this.createHeader('status', i18n.t('status'), {
                format: 'enum',
                filterable: true,
                enum: IntegrationStatusEnum,
                enumA11yKey: 'enums.integration_status',
                width: '8rem',
                sortable: true
            })
        ];
    }
}

export default IntegrationCollection;
