import { BaseModel } from '@/models';
import i18n from '@/lib/i18n';

export class BankAccount extends BaseModel {
    static entity = 'bankAccounts';

    static fields() {
        return {
            ...super.fields(),
            $verifying: this.boolean(false),
            name: this.string(null).nullable(),
            bank_name: this.string(null).nullable(),
            account_name: this.string(null).nullable(),
            account_number: this.string(null).nullable(),
            sort_code: this.string(null).nullable(),
            iban: this.string(null).nullable(),
            primary: this.boolean(null).nullable(),
            verified: this.boolean(false)
        };
    }

    get user_can_verify() {
        return this.Auth().is_gi;
    }

    get is_valid() {
        return [this.account_name, this.account_number, this.sort_code].every(v => v && v.length > 0);
    }

    get computed_name() {
        if (!this.name && !this.account_name) {
            return i18n.t('bank_account');
        }

        let name = this.name || this.account_name;

        if (this.sort_code) {
            name += ` - ${this.sort_code}`;
        }

        if (this.account_number) {
            name += ` - ${this.account_number}`;
        }

        return name;
    }
}

export default BankAccount;
