import { HoldingCollection, ProductCollection, Holding } from '@/models';
import i18n from '@/lib/i18n';

export class HoldingSingleCompanyCollection extends HoldingCollection {
    static type = 'HoldingSingleCompanyCollection';
    static endpoint = Holding.api.byCompany;

    get headers() {
        let headers = [
            // https://growthinvest.atlassian.net/browse/CORE-878
            // this.createHeader('investor_name', i18n.t('investor'), {
            //     path: 'investor.name',
            //     width: '15rem',
            //     minWidth: '15rem'
            // }),
            this.createHeader('name', i18n.t('name'), {
                width: '14rem',
                minWidth: '14rem',
                transform: data => {
                    if (data.item?.multi_investment === true) {
                        return i18n.t('co_invest');
                    }
                    return data.item.name;
                }
            }),
            this.createHeader('pending_allotment', i18n.t('pending_allotment'), {
                filterable: {
                    operators: this.getOperators(['eq'])
                },
                format: 'boolean',
                active: false
            }),
            this.createHeader('gross_investment_amount', i18n.t('gross_investment_value'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem',
                sortable: false
            }),
            this.createHeader('invested_amount', i18n.t('invested_amount'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('tax_relief', i18n.t('tax_relief'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),

            this.createHeader('current_value_moic', i18n.t('current_value_moic'), {
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '8rem',
                sortable: false
            }),
            this.createHeader('current_value', i18n.t('current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav', i18n.t('nav_current_value'), {
                column: 'current_value',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_exits', i18n.t('total_exits'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                format: 'number',
                width: '8rem',
                sortable: false
            }),
            this.createHeader('share_issue_price', i18n.t('share_issue_price'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('current_share_price', i18n.t('current_share_price'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '8rem',
                sortable: false
            }),
            this.createHeader('distributions', i18n.t('distributions'), {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '10rem',
                sortable: false
            }),

            this.createHeader('total_return', i18n.t('total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return', i18n.t('nav_total_return'), {
                column: 'total_return',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_moic', i18n.t('nav_total_return_moic'), {
                column: 'total_return_moic',
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_irr', i18n.t('total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_irr', i18n.t('nav_total_return_irr'), {
                column: 'total_return_irr',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '8rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_percentage', i18n.t('nav_total_return_percentage'), {
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_profit_loss', i18n.t('total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_profit_loss', i18n.t('nav_total_return_profit_loss'), {
                column: 'total_return_profit_loss',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                column: 'total_return_including_tax_relief',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9.2rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader(
                'nav_total_return_including_tax_relief',
                i18n.t('nav_total_return_including_tax_relief'),
                {
                    column: 'total_return_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '9.2rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_moic',
                i18n.t('nav_total_return_including_tax_relief_moic'),
                {
                    column: 'total_return_including_tax_relief_moic',
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_irr',
                i18n.t('total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_irr',
                i18n.t('nav_total_return_including_tax_relief_irr'),
                {
                    column: 'total_return_including_tax_relief_irr',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '11rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_including_tax_relief_percentage',
                i18n.t('nav_total_return_including_tax_relief_percentage'),
                {
                    column: 'total_return_including_tax_relief_percentage',
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    width: '10.2rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader(
                'total_return_profit_loss_including_tax_relief',
                i18n.t('total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: !this.show_nav_totals,
                    sortable: false
                }
            ),
            this.createHeader(
                'nav_total_return_profit_loss_including_tax_relief',
                i18n.t('nav_total_return_profit_loss_including_tax_relief'),
                {
                    column: 'total_return_profit_loss_including_tax_relief',
                    format: 'money',
                    formatOptions: this.money_format_options,
                    width: '14rem',
                    active: this.show_nav_totals,
                    sortable: false
                }
            ),

            this.createHeader('total_return_with_exits', i18n.t('total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('nav_total_return_with_exits', i18n.t('nav_total_return_with_exits'), {
                column: 'total_return_with_exits',
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                active: this.show_nav_totals,
                sortable: false
            }),

            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem',
                sortable: false
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'name',
            'gross_investment_amount',
            'invested_amount',
            'tax_relief',
            'distributions',
            'total_exits',

            'current_value',
            'total_return',
            'total_return_moic',
            'total_return_percentage',
            'total_return_including_tax_relief',
            'total_return_including_tax_relief_moic',
            'total_return_including_tax_relief_percentage'
        ];
    }

    get pdf_headers() {
        return [
            'name',
            'gross_investment_amount',
            'invested_amount',
            'tax_relief',
            'total_exits',

            'current_value',
            'nav',

            'total_return',
            'nav_total_return',

            'total_return_percentage',
            'nav_total_return_percentage',

            'total_return_including_tax_relief',
            'nav_total_return_including_tax_relief',

            'total_return_including_tax_relief_percentage',
            'nav_total_return_including_tax_relief_percentage'
        ];
    }

    async transform_item(item) {
        let products = [];

        try {
            products = (await new ProductCollection({ company_id: item.company_id }).request()).data;
        } catch (error) {
            console.error(error);
        }

        item.products = products;

        return item;
    }

    get preset_filters() {
        let filters = {
            ...super.preset_filters
        };

        if (this.show_closed_positions) {
            filters.show_closed = true;
        }

        return filters;
    }
}

export default HoldingSingleCompanyCollection;
