const ipToLong = ip => {
    return ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0) >>> 0;
};

const ipInRange = (ip, ranges) => {
    const ipLong = ipToLong(ip);

    return ranges.some(range => {
        const [rangeIp, prefix] = range.split('/');
        const rangeLong = ipToLong(rangeIp);
        const mask = ~(2 ** (32 - parseInt(prefix, 10)) - 1);

        return (ipLong & mask) === (rangeLong & mask);
    });
};

export default ipInRange;
