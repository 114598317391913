/* eslint-disable max-len */

export const external_urls = {
    companieshouse: 'https://find-and-update.company-information.service.gov.uk/company',
    facebook: 'https://facebook.com',
    growthinvest_investor_tcs: 'https://growthinvest.com/investor-terms-and-conditions/',
    growthinvest_privacy_agreement: 'https://growthinvest.com/privacy-policy/',
    growthinvest_risk_summary: 'https://growthinvest.com/risk-summaries/',
    growthinvest_knowledge_centre: 'https://growthinvest.com/knowledge-centre-welcome/',
    growthinvest_integrations: 'https://growthinvest.com/integrations/',
    james_brearley_oep:
        'https://www.jbrearley.co.uk/individual-investors/wp-content/uploads/2023/08/Order-Execution-Policy-Aug-23.pdf',
    james_brearley_privacy_policy: 'https://www.jbrearley.co.uk/privacy-policy/',
    james_brearley_tcs:
        'https://www.jbrearley.co.uk/individual-investors/wp-content/uploads/2024/06/JB-Service-Terms-and-Conditions-JUNE-2024-1.pdf',
    linkedin: 'https://linkedin.com',
    lse: 'https://www.londonstockexchange.com',
    twitter: 'https://twitter.com',
    woodside_tcs: 'https://woodsidecorporateservices.co.uk/WCSL_Investor-Terms-and-Conditions.pdf'
};

export default external_urls;
