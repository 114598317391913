import Vue from 'vue';
import VueLodash from 'vue-lodash';

import camelCase from 'lodash/camelCase';
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import drop from 'lodash/drop';
import dropRight from 'lodash/dropRight';
import each from 'lodash/each';
import fill from 'lodash/fill';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import has from 'lodash/has';
import head from 'lodash/head';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import kebabCase from 'lodash/kebabCase';
import keys from 'lodash/keys';
import last from 'lodash/last';
import map from 'lodash/map';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import range from 'lodash/range';
import set from 'lodash/set';
import snakeCase from 'lodash/snakeCase';
import sortBy from 'lodash/sortBy';
import startsWith from 'lodash/startsWith';
import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import toInteger from 'lodash/toInteger';
import toString from 'lodash/toString';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import values from 'lodash/values';

Vue.use(VueLodash, {
    lodash: {
        camelCase,
        clone,
        debounce,
        drop,
        dropRight,
        each,
        fill,
        filter,
        find,
        findIndex,
        findKey,
        get,
        has,
        head,
        isArray,
        isBoolean,
        isEmpty,
        isEqual,
        isFunction,
        isNil,
        isNull,
        isNumber,
        isObject,
        isPlainObject,
        isString,
        isUndefined,
        kebabCase,
        keys,
        last,
        map,
        merge,
        omit,
        omitBy,
        pick,
        pickBy,
        range,
        set,
        snakeCase,
        startCase,
        sortBy,
        startsWith,
        sumBy,
        toInteger,
        toString,
        uniq,
        upperFirst,
        values
    }
});
