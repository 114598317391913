var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:_vm.classList,style:({
        '--v-app-bar-height': '6rem',
        '--v-app-breadcrumbs-height': '2rem',
        '--v-app-risk-warning-height': '2rem',
        '--v-app-compatibility-warning-height': '2rem',
        '--v-container-height': _vm.containerHeight,
        '--v-app-bar-top': _vm.appBarTop,
        '--v-app-main-padding-top': _vm.appMainPaddingTop
    })},[(_vm.showApp && _vm.$vuetify.breakpoint.mobile)?_c('app-menu-panel'):_vm._e(),_c('app-snack-queue'),_c('app-dialog-queue'),_c('app-filter-panels'),_c('app-media-library'),(_vm.showApp)?[(_vm.$store.getters['app/mock_api'])?_c('v-snackbar',{staticStyle:{"z-index":"8"},attrs:{"value":_vm.showMockingApiAlert,"timeout":-1,"absolute":"","centered":"","top":"","elevation":2,"color":"primaryText"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('app-button',{attrs:{"icon":"close","color":"white","small":""},on:{"click":function($event){_vm.showMockingApiAlert = false}}})]},proxy:true}],null,false,2464008975)},[(_vm.isStaging())?[_vm._v("This is a staging environment with a mocked API")]:[_vm._v("This is a local development environment with a mocked API")]],2):_vm._e(),(_vm.showRiskWarning)?_c('app-risk-warning'):_vm._e(),(_vm.showCompatibilityWarning)?_c('app-compatibility-warning'):_vm._e(),(_vm.showAppBar)?_c('app-bar'):_vm._e(),(_vm.showCrumbs)?_c('app-breadcrumbs'):_vm._e(),_c('v-main',{staticClass:"app-main",class:{ 'app-main--logged-in': _vm.showAppBar }},[_c('transition',{attrs:{"appear":"","name":"fade","mode":"out-in"}},[_c('router-view')],1),_c('app-dialog',{attrs:{"size":"large","persistent":false,"cancel-button-text":_vm.$t('proceed'),"hide-continue-button":"","on-cancel-fn":function () { return (_vm.recategorised = false); },"title":_vm.$t('thank_you_for_completing_your_categorisation'),"overline":_vm.$t('categorisation')},model:{value:(_vm.recategorised),callback:function ($$v) {_vm.recategorised=$$v},expression:"recategorised"}},[_c('v-card-text',{staticClass:"px-6"},[_vm._v(" "+_vm._s(_vm.$t('were_required_to_revalidate_this_information_with_you_every_12_months'))+" ")])],1),_c('app-user-feedback'),_c('app-platform-modal'),_c('app-progress-queue'),(!_vm.Auth().onboarding)?_c('app-terms-modal',{on:{"accepted":function($event){_vm.$router.replace({
                        path: _vm.$route.path,
                        query: Object.assign({}, _vm.$route.query, {tcs: undefined})
                    });
                    _vm.replaceQueryParam('tcs', null);}},model:{value:(_vm.requireTermsAcceptance),callback:function ($$v) {_vm.requireTermsAcceptance=$$v},expression:"requireTermsAcceptance"}}):_vm._e(),_c('app-session-expired',{model:{value:(_vm.sessionExpired),callback:function ($$v) {_vm.sessionExpired=$$v},expression:"sessionExpired"}})],1)]:(_vm.showAccountSwitcher)?_c('app-dialog',{attrs:{"persistent":"","hide-actions":"","title":_vm.$t('please_select_an_account')}},[_c('v-card-text',[_c('app-account-switcher-list')],1)],1):_c('v-overlay',{attrs:{"color":"background","opacity":"1"}},[_c('v-progress-circular',{attrs:{"size":100,"width":7,"color":_vm.Auth().branding().colour('primaryBackground'),"indeterminate":""}})],1),_c('app-loading')],2)}
var staticRenderFns = []

export { render, staticRenderFns }