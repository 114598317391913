import { BaseModel, Account, Holding, CorporateAction, Product, Tranche } from '@/models';

import AccountTypeEnum from '@/enums/account/type';
import TransactionTypeEnum, { TransactionTypeEnumLegacy } from '@/enums/transaction/type';

import get from 'lodash/get';

import TransactionApi from '@/api/TransactionApi';

export class Transaction extends BaseModel {
    static name = 'Transaction';
    static entity = 'transactions';
    static Api = TransactionApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.attr(null),
            type: this.enum(TransactionTypeEnumLegacy).nullable(),
            amount: this.attr(null).nullable(),
            description: this.string(null).nullable(),
            reconciled: this.boolean(false).nullable(),
            locked: this.boolean(false).nullable(),
            record_at: this.string(null).nullable(),
            accrue_at: this.string(null).nullable(),
            application_at: this.string(null).nullable(),
            value_at: this.string(null).nullable(),
            source: this.string(null).nullable(),
            external_client_ref: this.string(null).nullable(),
            processor_client_code: this.string(null).nullable(),
            wrapper: this.string(null).nullable(),
            ri: this.string(null).nullable(),
            ifa: this.string(null).nullable(),
            transaction_date: this.string(null).nullable(),
            account_type: this.enum(AccountTypeEnum).nullable(),
            p1_client_code: this.string(null).nullable(),
            legacy_user_id: this.string(null).nullable(),
            received_on: this.string(null).nullable(),
            reconciled_at: this.string(null).nullable(),
            gi_ref: this.string(null).nullable(),
            last_updated_at: this.string(null).nullable(),
            share_quantity: this.number(null).nullable(),
            share_price: this.attr(this.defaultMoney).nullable(),
            on_platform: this.boolean(false).nullable(),
            in_custody: this.boolean(false).nullable(),
            pending_allotment: this.boolean(false).nullable(),
            running_balance: this.attr(null).nullable(),

            account_id: this.string(null).nullable(),
            account: this.belongsTo(Account, 'account_id'),

            holding_id: this.string(null).nullable(),
            holding: this.belongsTo(Holding, 'holding_id'),

            // Tranche
            fund_investment_id: this.string(null).nullable(),
            fund_investment: this.belongsTo(Tranche, 'fund_investment_id'),

            corporate_action_id: this.string(null).nullable(),
            corporate_action: this.belongsTo(CorporateAction, 'corporate_action_id'),

            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id'),

            // Used to link the platform/fee account transactions for payments as a matching pair.
            parent_id: this.string(null).nullable(),
            parent: this.belongsTo(Transaction, 'parent_id')
        };
    }

    static mock() {
        return {
            id: faker => faker.string.uuid(),
            type: faker => faker.helpers.arrayElement(Object.values(TransactionTypeEnum)),
            amount: faker => ({
                amount: faker.number.float(1000, 100000),
                currency: 'GBP'
            }),
            description: faker => faker.lorem.sentence(),
            reconciled: faker => faker.datatype.boolean(),
            locked: faker => faker.datatype.boolean(),
            record_at: faker => faker.date.past().toISOString(),
            accrue_at: faker => faker.date.past().toISOString(),
            application_at: faker => faker.date.past().toISOString(),
            value_at: faker => faker.date.past().toISOString(),
            source: faker => faker.lorem.word(),
            external_client_ref: faker => faker.lorem.word(),
            processor_client_code: faker => faker.lorem.word(),
            wrapper: faker => faker.lorem.word(),
            ri: faker => faker.lorem.word(),
            ifa: faker => faker.lorem.word(),
            transaction_date: faker => faker.date.past().toISOString(),
            account_type: faker => faker.helpers.arrayElement(Object.values(AccountTypeEnum)),
            p1_client_code: faker => faker.lorem.word(),
            legacy_user_id: faker => faker.lorem.word(),
            received_on: faker => faker.date.past().toISOString(),
            reconciled_at: faker => faker.date.past().toISOString(),
            gi_ref: faker => faker.lorem.word(),
            last_updated_at: faker => faker.date.past().toISOString(),
            share_quantity: faker => faker.datatype.number(),
            share_price: faker => ({
                amount: faker.number.float(1, 100),
                currency: 'GBP'
            }),
            on_platform: faker => faker.datatype.boolean(),
            in_custody: faker => faker.datatype.boolean(),
            pending_allotment: faker => faker.datatype.boolean()
        };
    }

    get currency() {
        return get(this.account, 'currency') || 'GBP';
    }

    get is_purchase() {
        return this.type === TransactionTypeEnum.PURCHASE;
    }
    get is_sale() {
        return this.type === TransactionTypeEnum.SALE;
    }

    get is_cash_transaction() {
        return [
            TransactionTypeEnum.CASH_RECEIPT,
            TransactionTypeEnum.CASH_WITHDRAWAL,
            TransactionTypeEnum.ADJUSTMENT,
            TransactionTypeEnum.INTEREST,
            TransactionTypeEnum.ESCROW,
            TransactionTypeEnum.RD_PAYMENT,
            TransactionTypeEnum.REBATE,
            TransactionTypeEnum.OPERATING_EXPENSE,
            TransactionTypeEnum.DISTRIBUTION,
            TransactionTypeEnum.FUND_TRANSFER
        ].includes(this.type);
    }

    get is_fee_transaction() {
        return [
            TransactionTypeEnum.FEE_PLATFORM,
            TransactionTypeEnumLegacy.FEE_ADVISER,
            TransactionTypeEnum.FEE_PROVIDER,
            TransactionTypeEnum.FEE_PRODUCT,
            TransactionTypeEnum.FEE_PARTNER,
            TransactionTypeEnum.FEE_PLATFORM_ACCRUAL,
            TransactionTypeEnum.FEE_PLATFORM_MISC,
            TransactionTypeEnum.FEE_ADVISER_INITIAL_ACCRUAL,
            TransactionTypeEnum.FEE_ADVISER_ONGOING_ACCRUAL,
            TransactionTypeEnum.FEE_ADVISER_INITIAL,
            TransactionTypeEnum.FEE_ADVISER_ONGOING
        ].includes(this.type);
    }

    get has_tranche() {
        return this.fund_investment || this.fund_investment_id;
    }

    get calculated_share_price() {
        if (!this.amount?.amount) {
            return null;
        }

        if (!this.share_quantity) {
            return null;
        }

        const amount = Number((this.amount.amount / this.share_quantity).toFixed(8));

        return {
            amount: amount < 0 ? amount * -1 : amount,
            currency: this.currency
        };
    }

    get share_price_match() {
        return !isNaN(parseFloat(this.share_price?.amount)) &&
            !isNaN(parseFloat(this.calculated_share_price?.amount)) &&
            this.share_price.amount === this.calculated_share_price.amount
            ? true
            : false;
    }

    get calculated_share_quantity() {
        if (!this.amount?.amount) {
            return null;
        }

        if (!this.share_price?.amount) {
            return null;
        }

        const amount = this.amount.amount < 0 ? this.amount.amount * -1 : this.amount.amount;

        return Number((amount / this.share_price.amount).toFixed(8));
    }

    get share_quantity_match() {
        return this.share_quantity &&
            this.calculated_share_quantity &&
            this.share_quantity === this.calculated_share_quantity
            ? true
            : false;
    }

    get calculated_amount() {
        if (!this.share_quantity || isNaN(parseFloat(this.share_price?.amount))) {
            return {
                amount: 0,
                currency: this.currency
            };
        }

        return {
            amount: Number((this.share_quantity * this.share_price.amount).toFixed(8)),
            currency: this.currency
        };
    }

    get amount_match() {
        return !isNaN(parseFloat(this.amount?.amount)) &&
            !isNaN(parseFloat(this.calculated_amount?.amount)) &&
            this.amount.amount === this.calculated_amount.amount
            ? true
            : false;
    }

    get amount_discrepancy() {
        if (isNaN(parseFloat(this.amount?.amount)) || isNaN(parseFloat(this.calculated_amount?.amount))) {
            return {
                amount: 0,
                currency: this.currency
            };
        }

        return {
            amount: Number(this.calculated_amount.amount - this.amount.amount),
            currency: this.currency
        };
    }
}

export default Transaction;
