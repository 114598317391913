import store from '@/store';
import Auth from '@/models/Auth';

const features = async (to, from, next) => {
    const auth = Auth();

    if (auth.is_gi && to.matched.length) {
        for (const route of to.matched) {
            const currentComponentName =
                typeof route.components.default === 'function'
                    ? (await route.components.default()).default?.name
                    : route.components.default?.name;
            if (currentComponentName) {
                const flag = store.getters['features/view'](currentComponentName);

                if (flag && !flag.disabled && flag.active) {
                    try {
                        route.components.default = () => import(`@/views/${currentComponentName}.next.vue`);
                    } catch (error) {
                        console.error(`Error importing next version of ${currentComponentName}:`, error);
                    }
                }
            }
        }
    }

    next();
};

export default features;
