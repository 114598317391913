import { BaseModel, Offer, FeeElement } from '@/models';
import FeePlanApi from '@/api/FeePlanApi';
import FeeTaxTypeEnum from '@/enums/fee/taxType';
import FeeTypeEnum from '@/enums/fee/type';

export class FeePlan extends BaseModel {
    static name = 'FeePlan';
    static entity = 'feePlans';
    static Api = FeePlanApi;
    static runSetup = true;

    setupLogic() {
        if (Array.isArray(this.entries) && this.entries.length) {
            const default_fee_elements = this.default_fee_elements;

            this.entries = this.entries.map(entry => {
                if (!(entry instanceof FeeElement)) {
                    entry = new FeeElement(entry);
                }

                if (this.tax_type) {
                    entry.tax_type = this.tax_type;
                }

                if (!entry.group) {
                    entry.group = entry.group_from_type;
                }

                if (!entry.name) {
                    entry.name = this.generateFeeName(entry);
                }

                if (entry.is_first_of_type && default_fee_elements.some(el => el.like(entry))) {
                    entry.locked = true;
                }

                return entry;
            });

            this.setMetadata();
        }
    }

    generateFeeName(entry) {
        const baseName = entry.name_from_type;
        const count = this.names_with_count[`${baseName}`] || 0;
        return `${baseName}_${count + 1}`;
    }

    setMetadata() {
        if (Array.isArray(this.entries)) {
            this.entries.forEach(entry => {
                if (entry.metadata_keys.length) {
                    entry.metadata = entry.metadata_keys.reduce((metadata, key) => {
                        metadata[`${key}`] = this[`${key}`];
                        return metadata;
                    }, {});
                } else {
                    entry.metadata = {};
                }
            });
        }
    }

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(null).nullable(),
            tax_type: this.enum(FeeTaxTypeEnum).nullable(),
            average_aim_companies_in_portfolio: this.number(null).nullable(),
            rebalance_percentage: this.number(null).nullable(),
            max_years_amc_reserved: this.number(null).nullable(),
            entries: this.modelList(FeeElement, []).nullable(),
            parent: this.attr(null).nullable(),
            group_descriptions: this.attr({}).nullable(),

            offer_id: this.string(null).nullable(),
            offer: this.belongsTo(Offer, 'offer_id'),

            // The following legacy fields will be removed in the future

            initial: this.number(null).nullable(),
            initial_description: this.string(null).nullable(),

            existing_investor_initial: this.number(null).nullable(),
            existing_investor_initial_description: this.string(null).nullable(),

            annual_management: this.number(null).nullable(),
            annual_management_description: this.string(null).nullable(),

            performance: this.number(null).nullable(),
            performance_description: this.string(null).nullable(),

            performance_hurdle: this.string(null).nullable(),

            additional: this.number(null).nullable(),
            additional_description: this.string(null).nullable(),

            administration: this.attr(this.defaultMoney).nullable(),
            administration_description: this.string(null).nullable(),

            investee_initial: this.number(null).nullable(),
            investee_initial_description: this.string(null).nullable(),

            investee_ongoing: this.number(null).nullable(),
            investee_ongoing_description: this.string(null).nullable(),

            investee_additional: this.number(null).nullable(),
            investee_additional_description: this.string(null).nullable()
        };
    }

    get parent_id() {
        return this.parent?.id || null;
    }

    get parent_name() {
        return this.parent?.name || null;
    }

    get default_fee_elements() {
        if (!this.tax_type) {
            return [];
        }

        if (!(this.tax_type in this.entries_template_map)) {
            return [];
        }

        const elements = this.entries_template_map[this.tax_type].map(
            fe =>
                new FeeElement({
                    tax_type: this.tax_type,
                    locked: true,
                    ...fe
                })
        );

        return elements.map(element => {
            if (!element.group) {
                element.group = element.group_from_type;
            }

            if (!element.name) {
                element.name = this.generateFeeName(element);
            }
            return element;
        });
    }

    get type() {
        return this.tax_type;
    }
    set type(taxType) {
        this.tax_type = taxType;

        if (this.tax_type in this.entries_template_map) {
            this.entries = this.default_fee_elements;

            this.average_aim_companies_in_portfolio = null;
            this.rebalance_percentage = null;
            this.max_years_amc_reserved = null;

            this.setup();
        } else {
            this.entries = [];
            throw new Error(`Unsupported tax type: ${taxType}`);
        }
    }

    get has_entries() {
        if (!this.entries) {
            return false;
        }

        if (this.entries.length === 0) {
            return false;
        }

        return this.entries.some(fe => fe.$dirty);
    }

    get entries_template_map() {
        return {
            [FeeTaxTypeEnum.EIS]: FeeElementsTemplate.SEISEIS,
            [FeeTaxTypeEnum.SEIS]: FeeElementsTemplate.SEISEIS,
            [FeeTaxTypeEnum.IHT]: FeeElementsTemplate.IHT,
            [FeeTaxTypeEnum.VCT]: FeeElementsTemplate.VCT
        };
    }

    get group_keys() {
        const entryGroups = this.entries.map(element => element.group).filter(group => group !== null);
        const descriptionGroups = Object.keys(this.group_descriptions || {});
        return [...new Set([...entryGroups, ...descriptionGroups])];
    }

    get groups() {
        const groups = this.group_keys.reduce((acc, key) => {
            acc[`${key}`] = [];
            return acc;
        }, {});

        this.entries.forEach(element => {
            if (element.group && element.group in groups) {
                groups[element.group].push(element);
            }
        });

        return groups;
    }

    get names_with_count() {
        const names = {};
        this.entries.forEach(element => {
            const name = element.name_from_type;

            if (!names[`${name}`]) {
                names[`${name}`] = 0;
            }

            names[`${name}`]++;
        });
        return names;
    }

    get show_average_aim_companies_in_portfolio() {
        return this.type === FeeTaxTypeEnum.IHT;
    }
    get show_rebalance_percentage() {
        return this.type === FeeTaxTypeEnum.IHT;
    }
    get show_max_years_amc_reserved() {
        switch (this.type) {
            case FeeTaxTypeEnum.EIS:
            case FeeTaxTypeEnum.SEIS:
                return true;
            default:
                return false;
        }
    }

    get max_years_reserved() {
        switch (this.type) {
            case FeeTaxTypeEnum.EIS:
            case FeeTaxTypeEnum.SEIS:
                return this.max_years_amc_reserved;
            default:
                return null;
        }
    }
}

export const FeeElementsTemplate = {
    SEISEIS: [
        {
            label: 'Initial Fee',
            type: FeeTypeEnum.INITIAL
        },
        {
            label: 'Annual Management Charge',
            type: FeeTypeEnum.ANNUAL_MANAGEMENT
        },
        {
            label: 'Performance Fee',
            type: FeeTypeEnum.PERFORMANCE
        },
        {
            label: 'Initial Fee',
            type: FeeTypeEnum.INVESTEE_INITIAL
        },
        {
            label: 'Annual Management Charge',
            type: FeeTypeEnum.INVESTEE_ANNUAL_MANAGEMENT
        }
    ],
    VCT: [
        {
            label: 'Annual Management Charge',
            type: FeeTypeEnum.ANNUAL_MANAGEMENT
        },
        {
            label: 'Performance Fee',
            type: FeeTypeEnum.PERFORMANCE
        }
    ],
    IHT: [
        {
            label: 'Initial Fee',
            type: FeeTypeEnum.INITIAL
        },
        {
            label: 'Annual Management Charge',
            type: FeeTypeEnum.ANNUAL_MANAGEMENT
        },
        {
            label: 'Performance Fee',
            type: FeeTypeEnum.PERFORMANCE
        },
        {
            label: 'Initial Fee',
            type: FeeTypeEnum.INVESTEE_INITIAL
        },
        {
            label: 'Annual Management Charge',
            type: FeeTypeEnum.INVESTEE_ANNUAL_MANAGEMENT
        }
    ]
};

export default FeePlan;
